import React from "react"
import Layout from "../components/layout/Layout"
import HeroImage from "../components/HeroImage"
import SEO from "../components/SEO"
import Button from "../components/Button"
import SplitSection from "../components/SplitSection"
import Img from 'gatsby-image';
import { graphql, Link } from "gatsby"
import blogData from "../data/blog-data"
import { BsBoxArrowInLeft, BsBoxArrowInRight } from "react-icons/bs"
import { ImHome } from "react-icons/im"

const BlogList = ({data, location, pageContext}) => {
    const { postsettings } = blogData()
    const settings = postsettings.childMarkdownRemark
    const postsfull = data.blogdata
    const { currentPage, numPages } = pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "/blog/" : ("/blog/"+(currentPage - 1).toString()+"/")
    const nextPage = ("/blog/"+(currentPage + 1).toString()+"/")
    return (
        <>
            <Layout location={location}>
                <SEO title={settings.frontmatter.seotitle} description={settings.frontmatter.seometadesc} image={settings.frontmatter.featureimage.publicURL} />
                <HeroImage markdown={settings} />
                <div className="container mx-auto">
                    <div className="text-left px-4 pt-4 pb-4">
                        <ul className="text-left text-xs md:text-base">
                            <li className="inline-block">
                            <Link to="/">
                                <span className="inline-block pr-2"><ImHome /></span>
                                <span className="px-1">Home</span>
                            </Link>
                            </li>
                            <li className="inline-block"><span>·</span></li>
                            <li className="inline-block">
                            <span className="px-1">{settings.frontmatter.title}</span>
                            </li>
                        </ul>
                    </div> 
                    {postsfull.edges.map((edge, index) => {
                                    const { frontmatter, fields } = edge.node
                                    const sectionOrder = index %2 === 0
                                    return (
                    <div key={fields.slug}>
                    <SplitSection reverseOrder={sectionOrder} primarySlot={
                        <div className="px-4">
                            <h3 className="text-3xl lg:text-5xl font-thin tracking-widest">{frontmatter.title}</h3>
                            <p className="mt-8 font-thin text-left lg:text-justify leading-relaxed">
                                {edge.node.excerpt}
                            </p>
                            <div className={sectionOrder?"pt-8 text-right": "pt-8 text-left"}>
                                <Link to={fields.slug} itemProp="url">
                                    <Button>Continue reading...</Button>
                                </Link>
                            </div>
                        </div>
                    }
                    secondarySlot={
                        frontmatter.featureimage.extension === 'svg' ? 
                        <img style={{width: "100%", maxHeight: "300px", background: "#FFF"}} src={frontmatter.featureimage.publicURL} alt={frontmatter.featureimagealt} />
                        : 
                        <Img style={{width: "100%", maxHeight: "300px", background: "#FFF"}} fluid={frontmatter.featureimage.childImageSharp.fluid} alt={frontmatter.featureimagealt} />
                    }
                    />
                    <div className="px-12 pt-20">
                        < hr />
                    </div>
                    </div>
                    )
                    })}
                    <div className="flex flex-row justify-between pt-8 px-12 md:px-12">
                        <div className="text-left">
                            {!isFirst && (
                                <Link to={prevPage} rel="prev"><BsBoxArrowInLeft size={34} className="inline-block" /> Previous Page</Link>
                            )}
                        </div>
                        <div className="text-left">
                            {!isLast && (
                                <Link to={nextPage} rel="prev">Next Page<BsBoxArrowInRight size={34} className="inline-block" /></Link>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default BlogList

export const query = graphql`
query($skip: Int!, $limit: Int!) {
    blogdata: allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/(blog)/.*[.]md$/"}}
        sort: { fields: [frontmatter___date], order: DESC }
        limit: $limit
        skip: $skip
    ) {
        edges {
            node {
                fields {
                    slug
                }
                id
                excerpt(pruneLength: 250)
                frontmatter {
                    date(formatString: "DD MMMM, YYYY")
                    title
                    featureimage {
                        childImageSharp {
                            fluid(maxWidth: 1536, srcSetBreakpoints: [640, 768, 1024, 1280]) {
                                ...GatsbyImageSharpFluid_withWebp 
                            }
                        }
                        extension
                        publicURL
                    }
                    featureimagealt
                }
            }
        }
    }
}
`