import { graphql, useStaticQuery } from 'gatsby'

const BlogData = () => {
    const BlogQuery = useStaticQuery(graphql`
    query {
        posts: allMarkdownRemark(limit: 3 sort: { order: DESC, fields: [frontmatter___date] }
            filter: {fileAbsolutePath: {regex: "/(blog)/.*[.]md$/"}}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "DD MMMM, YYYY")
                        title
                        featureimage {
                            childImageSharp {
                                fluid(maxWidth: 1536, srcSetBreakpoints: [640, 768, 1024, 1280]) {
                                    ...GatsbyImageSharpFluid_withWebp 
                                }
                            }
                            extension
                            publicURL
                        }
                        featureimagealt
                    }
                }
            }
        }
        postsfull: allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }
            filter: {fileAbsolutePath: {regex: "/(blog)/.*[.]md$/"}}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    id
                    excerpt(pruneLength: 250)
                    frontmatter {
                        date(formatString: "DD MMMM, YYYY")
                        title
                        featureimage {
                            childImageSharp {
                                fluid(maxWidth: 1536, srcSetBreakpoints: [640, 768, 1024, 1280]) {
                                    ...GatsbyImageSharpFluid_withWebp 
                                }
                            }
                            extension
                            publicURL
                        }
                        featureimagealt
                    }
                }
            }
        }
        postsettings: file(relativePath: {eq: "settings/settings-blog.md"}) {
            id
            childMarkdownRemark {
              frontmatter {
                lblblog
                seotitle
                seometadesc
                featureimage {
                    childImageSharp {
                        fluid(maxWidth: 1536, srcSetBreakpoints: [640, 768, 1024, 1280]) {
                            ...GatsbyImageSharpFluid_withWebp 
                        }
                    }
                  publicURL
                  extension
                }
                heading
                title
                featureimagealt
              }
            }
          }
    }
    `)
    return BlogQuery
}

export default BlogData